import React from "react";
import { Box, Typography, Grid, Container, Card, CardContent } from "@mui/material";
import { styled } from "@mui/system";
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
  },
}));
const FeatureList = () => {
  const data = [
    {
      title: "Edge",
      items: [
        "Edge IoT Hardware development",
        "Edge IoT Firmware and Application development",
        "Hardware and software for Sensors and Controllers",
        "Security Hardening Edge devices",
        "Test Jigs for automated production testing",
        "Substation Automation Hardware, Firmware, and Applications",
        "Docker container implementation for edge devices",
      ],
    },
    {
      title: "Applications",
      items: [
        "Protocol test tools",
        "Protocol Certification Software",
        "Automated test environment for Smart Metering / Distribution Automation",
        "Meter hardware, firmware, and cloud enablement",
        "Engineering Software Tools",
        "Disturbance data collection and monitoring tools",
        "Device data simulators and Head End System scalability testing",
      ],
    },
    {
      title: "Cloud and Data Center Applications",
      items: [
        "IoT Cloud Applications for Smart Metering",
        "Cloud deployed application modules",
        "Head-End Application servers",
        "Meter data management modules",
      ],
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#F4F7FC", py: 8 }}>
      <Container>
        <Typography
          variant="h3"
          sx={{
            color: "#2C2C2C",
            fontWeight: 700,
            mb: 6,
            textAlign: "center",
          }}
        >
          Our Expertise Areas
        </Typography>
        <Grid container spacing={4}>
          {data.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <StyledCard>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#007BFF",
                      fontWeight: 600,
                      mb: 3,
                      textAlign: "center",
                    }}
                  >
                    {section.title}
                  </Typography>
                  <ul style={{ listStyleType: "circle", paddingLeft: "20px", color: "#555" }}>
                    {section.items.map((item, itemIndex) => (
                      <li key={itemIndex} style={{ marginBottom: "8px" }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
export default FeatureList;






