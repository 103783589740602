import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import {useState} from 'react'
import { Link } from 'react-router-dom';
import image1 from './assets/standard-quality-control-collage-concept.jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
}));


const data = [
    {
      title: "New Product Development",
      items: [
        "For the creation of new products, we offer life cycle assistance, design, development, testing, and ideation advice."
      ],
      id: 'new-product-development'
    },
    {
      title: "Life Cycle Extension",
      items: [
        "We help companies extend the life cycle of mature and declining products. We have acted as an extended R&D team for several of our customers to enable life cycle extension"
      ],
      id: 'life-cycle-extension'
    },
    {
      title: "Solution based on Aspire IP",
      items: [
        "Our IP implementation practice helps you get your product to market faster because our expertise implementing protocol stacks for our customers."
      ],
      id: 'solutioning'
    },
    {
        title: "Testing and Validation",
        items: [
          "We help cutomers automate and execute their prduct life cycle. we maanage the hardware, firmwarem and software unit, regression and release testing."
        ],
        id: 'testing-validation'
    },
    {
        title: "Hardware Development",
        items: [
          "For our clients, we design, produce, maintain, and handle the life-cycle of ruggrf misson-critical gear. From concept to prototype to small-scale production to final launch, we collaborate with clients to provide production support and life-cycle assistance."
        ],
        id: 'hardware-development'
    },
    {
        title: "Certification & Compilance",
        items: [
          "We assist our clients in getting their products certified for conformity thanks to our vast consulting and domain knowledge. We offer process, pre-, and final compliance testing consultations, as well as handholding through the verification of hardware, security, and communication interface standards."
        ],
        id: 'certification-compliance'
    },
  ];

const Productengeering = () => {
    const [showExpertise, setShowExpertise] = useState(false);
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', bgcolor: '#f0f0f0', fontWeight: 'bold'}}>
                Product Engineering
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{ padding: '30px' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  color: '#000',
                }}
              >
                Product Engineering
              </Typography>
              <Typography sx={{ marginTop: '25px' }}>
              Strong domain expertise, market understanding, operational conditions, certification, and regulatory compliance knowledge are all necessary when developing products for the energy and utility sector. We have been the go-to partners for OEMs in the energy and utilities sector to create their products. We hope to use our experience and knowledge to help you engineer products more quickly, more affordably, and with greater quality.
              </Typography>
              
            </Grid>
            <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
          </Grid>
        </Container>
      </Box>

      

        

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
           <Container maxWidth="lg">
                <Typography 
                    variant='h5'
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}
                >What Aspire Product Engneering Service Offer?
                </Typography>
                <Typography 
                    sx={{
                        marginTop: '15px',
                        textAlign: 'left'
                    }}
                >
                    A team of creative and technically skilled individuals at Kalkitech can handle any utility product engineering need that our clients may have. We provide innovative solutions to create a useful utility product thanks to our extensive experience in the automation sector.
                    <ul>
                       <li>Field data acquisition and integration.</li>
                       <li>Operations management.</li>
                       <li>Quality management applications.</li>
                       <li>Cloud, IIoT and analytics.</li>
                       <li>Field device integration.</li>
                       <li>Secure remote device monitoring.</li>
                    </ul>
                </Typography>
           </Container>
        </Box>


        <Box sx={{ backgroundColor: "#f9f9f9", py: 6 }}>
            <Container>
                <Typography
                variant="h4"
                sx={{
                    color: "#2c2c2c",
                    fontWeight: 700,
                    mb: 4,
                    textAlign: "center",
                }}
                >
                Our Expertise Areas
                </Typography>
                <Grid container spacing={4}>
                {data.map((section, index) => (
                    <Grid item xs={12} md={4} key={index}>
                    <Box
                        sx={{
                        backgroundColor: "#fff",
                        p: 3,
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        height: "100%",
                        }}
                    >
                        <Typography
                        variant="h6"
                        sx={{
                            color: "#3D52A0",
                            fontWeight: 600,
                            mb: 2,
                            textAlign: "center",
                        }}
                        >
                        {section.title}
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                        {section.items.map((item, itemIndex) => (
                            <li key={itemIndex} style={{ marginBottom: "8px", color: "#555" }}>
                            {item}
                            </li>
                        ))}
                        </ul>
                        <Button 
                          variant="contained" 
                          color="#fff"
                          backgroundColor='#3D52A0'
                          sx={{ mt: 2 }}
                          component={Link} 
                          to={`/services/${section.id}`} // Update with your route path
                        >
                          Know More
                        </Button>
                    </Box>
                    </Grid>
                ))}
                </Grid>
            </Container>
        </Box>
    </Box>
  )
}

export default Productengeering
