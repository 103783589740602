import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

const Solutionbased = () => {
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', fontWeight: 'bold'}}>
            Solutioning based on Aspire IP
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                {/* <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid> */}

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        Solutioning based on Aspire IP            
                    </Typography>
                    <Typography>Aspire has over the years built significant IP in IoT / Cloud / Edge in Energy & Utilities. Aspire consulting team now brings these IP to our OEM customers for accelerating Go To Market. We customize, adapt and implement Aspire IP on OEM products and platforms.</Typography>
                   
                    
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0', textAlign: 'center'}}>
            <Container maxWidth="lg">
                <Typography variant='h4' sx={{fontWeight: 'bold'}} >Key Benefits</Typography>
                <Typography sx={{textAlign: 'left', marginTop: '25px'}}>The design is made simpler and the client may get to market more quickly by employing this IP model. We can help customers quickly transition to IoT infrastructure.  Customers can always rely on a tested implementation to provide them with correct information, as well as to aggregate and convert field device data from several suppliers. Our solution allows us to optimise resources and benchmark performance for Edge devices. </Typography>
                <Typography sx={{textAlign: 'left', marginTop: '25px'}}>Many proprietary and standards-based communication protocols are used by field equipment, which makes it more difficult and time-consuming for product suppliers to provide data conversion technologies. Our containerised deployment strategy will offer a reliable but scalable solution for simple and adaptable deployment. After the data has been gathered, it should ideally be combined and examined in a way that makes decisions easier and, in the end, improves efficiency and dependability.</Typography>
            </Container>
        </Box>

        <Box sx={{padding: '30px', textAlign: 'center'}}>
            <Typography variant='h4' sx={{fontWeight: 'bold'}}>Make an Enquiry</Typography>
            <Typography variant='h6' sx={{marginTop: '15px'}}>Get in Touch with Us</Typography>
            <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
        </Box>
    </Box>
  )
}

export default Solutionbased
