import React from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Paper,
  Button,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { 
  WorkOutline, 
  School, 
  Diversity3, 
  Timeline 
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Career = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const benefitCards = [
    {
      icon: <WorkOutline className="w-8 h-8" style={{ color: '#3D52A0' }} />,
      title: "Flexible Employment",
      description: "Choose from Permanent, Contract, or Casual positions to suit your lifestyle and career goals."
    },
    {
      icon: <School className="w-8 h-8" style={{ color: '#3D52A0' }} />,
      title: "Continuous Learning",
      description: "Access ongoing professional development opportunities and grow your expertise."
    },
    {
      icon: <Diversity3 className="w-8 h-8" style={{ color: '#3D52A0' }} />,
      title: "Inclusive Culture",
      description: "Join a diverse team that values unique perspectives and fosters collaboration."
    },
    {
      icon: <Timeline className="w-8 h-8" style={{ color: '#3D52A0' }} />,
      title: "Career Growth",
      description: "Clear pathways for advancement and opportunities to work on innovative projects."
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f9fafb' }}>
      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#3D52A0', color: 'white', py: 8, marginTop: '25px'}}>
        <Container maxWidth="lg">
          <Typography variant="h2" sx={{ fontSize: '2.5rem', fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
            Build Your Career With Us
          </Typography>
          <Typography variant="h5" sx={{ fontSize: '1.25rem', textAlign: 'center', mb: 4 }}>
            Join Aspire Coordination Australia and Shape the Future of Design
          </Typography>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* About Section */}
        <Paper sx={{ p: 4, mb: 6 }}>
          <Typography variant="h4" sx={{ mb: 2, fontSize: '1.5rem', fontWeight: 600 }}>
            About Our Company
          </Typography>
          <Typography sx={{ mb: 2, color: '#374151' }}>
            Aspire Coordination Australia is an innovative design consulting firm that provides a wide array of services, attracting professionals from diverse fields and expertise. Our success is driven by fresh ideas, and we continually seek talented individuals to enhance our projects.
          </Typography>
        </Paper>

        {/* Benefits Grid */}
        <Typography variant="h4" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 600 }}>
          Why Choose Us
        </Typography>
        <Grid container spacing={4} sx={{ mb: 6 }}>
          {benefitCards.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <Box sx={{ mb: 2 }}>
                    {benefit.icon}
                  </Box>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                    {benefit.title}
                  </Typography>
                  <Typography sx={{ color: '#6b7280' }}>
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Work Culture Section */}
        <Paper sx={{ p: 4, mb: 6, backgroundColor: '#f9fafb' }}>
          <Typography variant="h4" sx={{ mb: 2, fontSize: '1.5rem', fontWeight: 600 }}>
            Our Work Culture
          </Typography>
          <Typography sx={{ mb: 2, color: '#374151' }}>
            We at Aspire Coordination Australia understand that our employees are the key to our success. We are committed to creating a friendly, cooperative atmosphere where everyone may succeed. Experts from a wide range of disciplines make up our team, and we value the unique perspectives and experiences that each person brings to the table.
          </Typography>
        </Paper>

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontSize: '1.25rem', fontWeight: 600 }}>
            Ready to Start Your Journey?
          </Typography>
          <Typography sx={{ mb: 3, color: '#6b7280' }}>
            Whether you're an experienced professional or a recent graduate, we have opportunities for you.
          </Typography>
          <Button 
            variant="contained" 
            href="mailto:admin@aspirecoordination.com"
            sx={{ 
              backgroundColor: '#3D52A0', 
              '&:hover': { backgroundColor: '#1d4ed8' },
              px: 4, 
              py: 1.5 
            }}
          >
            Apply Now
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Career;