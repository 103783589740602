import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

const Lifecycle = () => {
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', fontWeight: 'bold'}}>
                Life Cycle Extension
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                {/* <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid> */}

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        Life Cycle Extension                
                    </Typography>
                    <Typography>We help our customers focus on next-generation products and manage the life cycle of mature and declining products. We have acted as Engineering Sustenance teams of several of our customers to maintain and add enhancements to existing products.</Typography>
                    
                   
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        For Mature Products              
                    </Typography>
                    <Typography>
                        <ul>
                            <li>We, partner, to improve the bottom line for mature products.</li>
                            <li>We manage compliance and certification testing on behalf of our customer.</li>
                            <li>We manage product roadmap for customers.</li>
                            <li>We manage customization and market adaptation for new markets.</li>
                        </ul>
                    </Typography>
                </Grid>

               

                <Grid item xs={12} md={6}>
                <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                       For Declining Products             
                    </Typography>
                    <Typography>
                        <ul>
                            <li>Our goal as partners is to enhance revenue leakage and safeguard the bottom line.</li>
                            <li>We oversee the life cycle management of turnkey products.</li>
                            <li>We manage all aspects of R&D, PM, EOL, operations, and after-sales.</li>
                            <li>We oversee the upkeep and maintenance.</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', textAlign: 'center'}}>
            <Typography 
                variant='h3'
                sx={{
                    fontWeight: 'bold',
                   
                }}
            >Make an Enquiry</Typography>
            <Typography
                variant='h5'
                sx={{
                    marginTop: '15px'
                }}
            >Get in Touch with Us</Typography>
           <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
        </Box>
    </Box>
  )
}

export default Lifecycle
