import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box as MuiBox 
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
import image1 from '../assets/programming-background-with-person-working-with-codes-computer.jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

// Custom styles for left-side accordion
const LeftSideAccordion = ({ faqs }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MuiBox sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            '&:before': {
              display: 'none',
            },
            marginBottom: 2,
            borderRadius: 2,
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowLeftIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: 0,
                marginLeft: 1,
              },
              '& .MuiAccordionSummary-content': {
                marginLeft: 1,
              },
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer.map((point, pointIndex) => (
                <MuiBox 
                  key={pointIndex} 
                  component="p" 
                  sx={{ 
                    margin: 0, 
                    padding: '8px 0',
                    '&:not(:last-child)': {
                      borderBottom: '1px solid rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  • {point}
                </MuiBox>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </MuiBox>
  );
};

const ITintegration = () => {
  const faqData = [
    {
      question: "OT Expertise",
      answer: [
        "Various make RTU expertise",
        "Various make PLC expertise",
        "Various make sensor expertise",
        "Various make SCADA / DMS / EMS expertise",
        "Custom software and hardware development expertise of OT drivers, Interfaces, Tools and Applications and devices"
      ]
    },
    {
      question: "IT Expertise",
      answer: [
        "Asset Management and Condition Based Maintenance application Integration expertise",
        "Cloud Integration Expertise with AWS, Azure, IBM, and Google",
        "IBM , SAP , Oracle , Siemens, SalesForce Enterprise application integration expertise",
        "Enterprise Software Expertise"
      ]
    }
  ];

  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', bgcolor: '#f0f0f0', fontWeight: 'bold'}}>
                IT/OT Integration
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        IT/OT Integration    
                    </Typography>
                    <Typography sx={{marginTop: '25px'}}>IT/OT integration is more crucial than ever in the age of digital transformation for all industries to use the data silos within their organisation and use them to make decisions that are crucial to business operations, customer satisfaction, or financial improvement. An effective IT/OT integration greatly increases management's awareness of their company's operations, key hazards, and business prospects.</Typography><br/>
                        <Typography>We assist businesses in smoothly integrating their OT and IT systems. Aspire has been in the forefront of helping businesses integrate legacy and standards-based OT systems together because of its solid IT experience and background in these systems.</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant='h3' sx={{fontWeight: 'bold', textAlign: 'center'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px', textAlign: 'center'}}>Get in Touch with us</Typography>
                    <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                      sx={{
                          border: '2px solid #ccc', 
                          borderRadius: '8px',      
                          padding: '20px',          
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                          bgcolor: '#fff',          
                      }}
                  >
                      <Typography
                          variant="h5"
                          sx={{
                              color: '#000',
                              marginBottom: '20px',
                              fontWeight: 'bold',   // Optional for emphasis
                          }}
                      >
                          Expertise
                      </Typography>
                      
                      {/* Added FAQ Section */}
                      <LeftSideAccordion faqs={faqData} />
                  </Box>
                </Grid>

            </Grid>
            </Container>
        </Box>
    </Box>
  )
}

export default ITintegration