import { useState } from 'react';
import React from 'react'
import { Container, Toolbar, Typography, Box, Grid, Card, CardContent, Button , Accordion, AccordionSummary, AccordionDetails   } from '@mui/material';
import { Award, Users, Target } from 'lucide-react';
import exampleImage1 from '../assets/sb1.png'
import { useLocation, Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';

const faqs = [
    {
        question: 'Tailored Solutions for Complex Needs',
        answer: 'Our ability to combine strong edge and cloud computing capabilities with insights into market conditions ensures that our solutions meet your unique needs. Whether optimizing existing systems or designing new ones, we focus on delivering measurable results.'
    },
    {
        question: 'Proven Track Record of Success',
        answer: 'Over the years, we’ve built a reputation for delivering reliable, innovative solutions that empower clients to stay ahead in a competitive market. Our commitment to quality and client satisfaction makes us a trusted name in the industry.'
    },
    {
        question: 'Collaborative and Customer-Centric Approach',
        answer: 'We prioritize understanding your challenges and goals to deliver solutions that align with your vision. Our collaborative approach ensures smooth project execution and sustainable growth for your business.'
    },
    {
        question: 'Future-Ready Technology Adoption',
        answer: 'By leveraging the latest advancements in cloud platforms, container technology, and UX design, we equip your business with solutions that are not only effective today but also adaptable for the future.'
    }
];


function Home() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);

  const handleClick = (index) => {
    setSelectedServiceIndex(index);
  };

  

  const features = [
    {
      title: "Digital Transformation",
      description: " We assist clients in integrating cloud platforms with their field data. We provide clients end-to-end solutions for digital transformation in the energy, utilities, commercial, and industrial sectors thanks to our strong edge and cloud expertise in AWS, Azure, Google Cloud, IBM Cloud, as well as docker and container technology, Streams technology, and UX tools.",
      id: 'digital-transformation'
      
    },
    {
      title: "Product Engineering",
      description: "Strong domain expertise, market understanding, operational conditions, certification, and regulatory compliance knowledge are all necessary when developing products for the energy and utility sector. We have been the go-to partners for OEMs in the energy and utilities sector to create their products. We hope to use our experience and knowledge to help you engineer products more quickly.",
      id: 'product-engineering'
      
    }
  ];

  return (
    <Box sx={{ bgcolor: 'white', width: '100%' }}>
        <Box sx={{ position: 'relative', width: '100%', height: '650px' }}>
            {/* Image */}
            <Box
                component="img"
                src={exampleImage1}
                alt="Engineering & IT Solutions"
                sx={{ width: '100%', height: '100%', objectFit: 'cover', color: 'linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
            />

            {/* Text Overlay */}
            <Typography
    variant="h4"
    sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: '#C99A40',
        textShadow: '0px 0px 8px rgba(0, 0, 0, 0.7)',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // semi-transparent black background
        padding: '10px 20px', // padding for spacing
        borderRadius: '5px' // rounded corners
    }}
>
Technology and Innovation Services by <br/> AspireCoordination
</Typography>

        </Box>

       <Box sx={{padding: '30px'}}>
       <Container component="main" maxWidth="lg" sx={{ p: 3, bgcolor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Toolbar />
            <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#3D52A0' }}>
                Technology and Innovation Services by AspireCoordination Australia Pty Ltd
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '15px' }}>
                <Typography>
                AspireCoordination Australia Pty Ltd is a premier provider Edge and Cloud-based software, hardware and middleware that enable digital transformation for Energy & Utilities, Industries and Enterprises.
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '15px' }}>
                <Typography>
                To create a digital, intelligent, cleaner, and more just world, Kalkitech integrates control systems, sensors, and computing systems. To help the industries we serve undergo digital transformation, we offer cloud software, application software, middleware, edge software, edge analytics, edge hardware, consultancy and implementation services, and integration solutions.
                </Typography>
            </Grid>
            </Grid>
        </Container>
       </Box>


        

        <Box sx={{ padding: '30px' ,textAlign: 'center', mb: 8, bgcolor: '#f0f0f0' }}>
           <Container maxWidth="lg">
           <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                mb: 3,
                background: '#000',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Our Services
            </Typography>

            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{padding: '10px'}}>
            <Grid item sx={{ marginTop: '15px' }}>
                <Typography>
                We assist Energy & Utility clients in accomplishing their R&D, engineering, and project implementation objectives in the areas of product engineering, cloud computing, IoT, and digital transformation. With more than years of experience providing technology solutions to international OEMs in the energy and utility sector, we provide our knowledge, skills, and domain expertise to lower development, implementation, and validation risk, shorten product life cycles, and boost profitability.
                </Typography>
            </Grid>
            </Grid>

            {/* Feature Cards */}
            <Grid container spacing={4} sx={{marginTop: '15px'}}>
              {features.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Container maxWidth="lg">
                  <Card 
                    sx={{ 
                      
                      height: '100%',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      borderRadius: '20px',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 8
                      }
                    }}
                  >
                    <CardContent sx={{ p: 4, textAlign: 'center' }}>
                      
                      <Typography 
                        variant="h5" 
                        component="div" 
                        sx={{ 
                          fontWeight: 'bold',
                          borderRadius: '20px',
                          mb: 2
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="text.secondary"
                        sx={{ lineHeight: 1.7 }}
                      >
                        {feature.description}
                      </Typography>
                      <Button 
                        
                        
                        sx={{ mt: 2 }}
                        component={Link} 
                        to={`/services/${feature.id}`} // Update with your route path
                       >
                        Know More
                       </Button>
                    </CardContent>
                  </Card>
                  </Container>
                </Grid>
              ))}
            </Grid>
           </Container>
          </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom sx={{textAlign: 'center', fontWeight: 'bold', color: '#3D52A0'}}>
                Why Choose Us
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography variant="h6">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}

            </Container>
        </Box>
    </Box>
  );
}

export default Home;