import React from "react";
import { Box, Container, Grid, Typography, IconButton, styled, CardMedia } from "@mui/material";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";  // Importing Link from react-router-dom
import logo from '../assets/Add a heading-4.svg';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a237e",
  color: "#ffffff",
  padding: theme.spacing(6, 0),
  position: "relative",
  bottom: 0,
  width: "100%"
}));

const FooterHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  fontSize: "1.2rem"
}));

const FooterLink = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  marginBottom: theme.spacing(1),
  cursor: "pointer",
  fontSize: "1rem",
  "&:hover": {
    color: "#3D52A0",
    transform: "translateX(5px)",
    transition: "all 0.3s ease-in-out"
  }
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  marginRight: theme.spacing(2),
  "&:hover": {
    color: "#90caf9",
    transform: "translateY(-3px)",
    transition: "all 0.3s ease-in-out"
  }
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(4),
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  paddingTop: theme.spacing(2)
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer component="footer" role="contentinfo" sx={{bgcolor: '#ADBBDA'}}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            {/* <FooterHeading variant="h6" aria-label="Company Information" sx={{color: '#0f0f0f', textTransform: 'uppercase'}}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <CardMedia
                  sx={{ height: 75, width: 155 }}
                  image={logo}
                  title="Aspire"
                />
              </Link>
            </FooterHeading>
            <Typography variant="body2" sx={{color: '#0f0f0f'}}>
              Leading innovation in technology solutions and digital transformation.
            </Typography> */}
            <FooterHeading variant="h6" aria-label="Help Section" sx={{color: '#000'}}>
              Email
              <Typography variant="body2" sx={{color: '#0f0f0f'}}>admin@aspirecoordination.com</Typography>
            </FooterHeading>

            <FooterHeading variant="h6" aria-label="Help Section" sx={{color: '#000'}}>
              Call Us
              <Typography variant="body2" sx={{color: '#0f0f0f'}}>+61 403 786 083</Typography>
            </FooterHeading>

            <FooterHeading variant="h6" aria-label="Help Section" sx={{color: '#000'}}>
              Find Us
              <Typography variant="body2" sx={{color: '#0f0f0f'}}>Ground Floor, 288 Musgrave Road, Coopers Plains, QLD, Australia 4108</Typography>
            </FooterHeading>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FooterHeading variant="h6" aria-label="Extra Links Section" sx={{color: '#0f0f0f', textTransform: 'uppercase'}}>
              Company
            </FooterHeading>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Home</FooterLink>
            </Link>
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>About</FooterLink>
            </Link>
            <Link to="/career" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Career</FooterLink>
            </Link>
            <Link to="/contact-us" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Contact</FooterLink>
            </Link>

            <FooterHeading variant="h7" aria-label="Resources Section" sx={{textTransform: 'uppercase', color: '#000'}}>
                Service
            </FooterHeading>
            <Link to="/services/digital-transformation" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Digital Transformation</FooterLink>
            </Link>
            <Link to="/services/product-engineering" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Product Engineering</FooterLink>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3} sx={{color: '#0f0f0f'}}>
            <FooterHeading variant="h6" aria-label="Resources Section" sx={{textTransform: 'uppercase'}}>
                Digital Transformation
            </FooterHeading>
            <Link to="services/cloud-integration" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>IoT Edge & Cloud Integration</FooterLink>
            </Link>
            <Link to="services/legacy-migration" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Legacy Protocol Migration</FooterLink>
            </Link>
            <Link to="services/it-ot-integration" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>IT/OT Integration</FooterLink>
            </Link>
            <Link to="services/advanced-grid" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Advanced Grid Automation Technology Lab</FooterLink>
            </Link>
          </Grid>

          

          <Grid item xs={12} sm={6} md={3} sx={{color: '#0f0f0f' }}>
            <FooterHeading variant="h6" aria-label="Resources Section" sx={{textTransform: 'uppercase'}}>
              Product Engineering 
            </FooterHeading>
            <Link to="services/new-product-development" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>New Product Development</FooterLink>
            </Link>
            <Link to="services/life-cycle-extension" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Life Cycle Extension</FooterLink>
            </Link>
            <Link to="services/solutioning" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Solution Based on Aspire IP</FooterLink>
            </Link>
            <Link to="services/testing-validation" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Testing and Validation</FooterLink>
            </Link>
            <Link to="services/hardware-development" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Hardware Development</FooterLink>
            </Link>
            <Link to="services/certification-compliance" style={{ textDecoration: 'none' }}>
              <FooterLink variant="body2" sx={{color: '#0f0f0f'}}>Certification and Compliance</FooterLink>
            </Link>
          </Grid>

          

          
        </Grid>


       

        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", alignItems: "center", color: '#0f0f0f' }}>
          <Box sx={{ mb: 2 ,color: '#0f0f0f' }}>
            <SocialIcon aria-label="Facebook" component="a" href=" https://www.facebook.com/profile.php?viewas=100000686899395&id=61568696157426">
              <FaFacebook size={24} />
            </SocialIcon>
            <SocialIcon aria-label="Twitter" component="a" href="https://x.com/AspireCDN7">
              <FaTwitter size={24} />
            </SocialIcon>
            <SocialIcon aria-label="Instagram" component="a" href="https://www.instagram.com/aspirecoordination7/">
              <FaInstagram size={24} />
            </SocialIcon>
            <SocialIcon aria-label="LinkedIn" component="a" href="https://www.linkedin.com/company/aspire-coordination/">
              <FaLinkedin size={24} />
            </SocialIcon>
          </Box>
          <CopyrightText variant="body2">
            © {currentYear} AspireCorp. All rights reserved.
          </CopyrightText>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
