import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import image1 from '../assets/top-view-master-works-broken-tablet-repair-it-near-tool-bag-wooden-table-service-lab-min.jpg'
import { Link } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));
const data = [
  {
    title: "Edge",
    items: [
      "Edge IoT Hardware development",
      "Edge IoT Firmware and Application development",
      "Hardware and software for Sensors and Controllers",
      "Security Hardening Edge devices",
      "Test Jigs for automated production testing",
      "Substation Automation Hardware, Firmware, and Applications",
      "Docker container implementation for edge devices",
    ],
  },
  {
    title: "Applications",
    items: [
      "Protocol test tools",
      "Protocol Certification Software",
      "Automated test environment for Smart Metering / Distribution Automation",
      "Meter hardware, firmware, and cloud enablement",
      "Engineering Software Tools",
      "Disturbance data collection and monitoring tools",
      "Device data simulators and Head End System scalability testing",
    ],
  },
  {
    title: "Cloud and Data Center Applications",
    items: [
      "IoT Cloud Applications for Smart Metering",
      "Cloud deployed application modules",
      "Head-End Application servers",
      "Meter data management modules",
    ],
  },
];

const Newproduct = () => {
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', fontWeight: 'bold'}}>
                New Product Development
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        New Product Development                
                    </Typography>
                    <Typography>For the development of new products, we assist our clients with ideation, design, development, testing, and life cycle support. We can provide the best support for product development because of our extensive industry and technical domain knowledge, top-notch procedures, and years of experience dealing with difficult goods.</Typography>
                    <Typography>serves as an extended research and development team for OEMs in the creation of new products, whether they are cloud, mobile, or hardware applications.
                        <ul>
                            <li>Design, development and product launch</li>
                            <li>Design and prototype consulting</li>
                            <li>Ideation consulting</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{ backgroundColor: "#f9f9f9", py: 6 }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            color: "#2c2c2c",
            fontWeight: 700,
            mb: 4,
            textAlign: "center",
          }}
        >
          Our Expertise Areas
        </Typography>
        <Grid container spacing={4}>
          {data.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 3,
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#3D52A0",
                    fontWeight: 600,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  {section.title}
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex} style={{ marginBottom: "8px", color: "#555" }}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#fff'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant='h3' sx={{fontWeight: 'bold', textAlign: 'center'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px', textAlign: 'center'}}>Get in Touch with us</Typography>
                    <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
                </Grid>

                <Grid item xs={12} md={6}>
                     <Box
                         sx={{
                            border: '2px solid #ccc', 
                            borderRadius: '8px',      
                            padding: '20px',          
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                            bgcolor: '#f0f0f0',          
                        }}
                    >
                        <Typography
                        variant='h5'
                        sx={{
                            
                            color: '#000',
                          
                        }}>
                            Key Advantages
                            <Typography>
                            <ul>
                                <li>Interoperability</li>
                                <li>Standards compliance and certification support</li>
                                <li>Implement products within budget</li>
                                <li>Reduced time to market</li>
                            </ul>
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            </Container>
        </Box>
    </Box>
  )
}

export default Newproduct
