import React, { useEffect } from 'react';
import { Container, Card, CardContent, Typography, Box, Toolbar, Paper, Grid } from '@mui/material';
import { Award, Users, Target } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const features = [
    {
      title: "Engineering Excellence",
      description: "Our team comprises highly skilled engineers, each bringing over a decade of hands-on experience across diverse industry sectors in Australia. This wealth of expertise ensures a deep understanding of Australian Standards, empowering us to deliver solutions that consistently exceed industry expectations.",
      icon: <Award size={40} />
    },
    {
      title: "Project Management Expertise",
      description: "Alongside our engineering expertise, our team at Tech Coordination Australia includes certified Project Management professionals skilled in PRINCE2 and AGILE methodologies. These specialists are adept at handling intricate projects with efficiency, flexibility and guaranteeing timely delivery.",
      icon: <Users size={40} />
    },
    {
      title: "Process Optimization Specialists",
      description: "Our team includes Six Sigma-certified process experts who specialize in assessing workflows, pinpointing inefficiencies, and deploying effective solutions. Their skills ensure that your operations achieve peak efficiency and deliver optimal results.",
      icon: <Target size={40} />
    }
  ];

  return (
    <Box sx={{ bgcolor: '#f8fafc', minHeight: '100vh' }}>
      <Container maxWidth="lg">
        <Box component="main" sx={{ py: 8 }}>
          <Toolbar />
          
          {/* Hero Section */}
          <Paper 
            elevation={0}
            sx={{ 
              textAlign: 'center',
              p: 6,
              mb: 8,
              borderRadius: '16px',
              background: '#f0f0f0',
              color: '#000'
            }}
          >
            <Typography 
              variant="h3" 
              sx={{ 
                fontWeight: 'bold',
                mb: 3
              }}
            >
              About Us
            </Typography>
            <Typography variant="h6" sx={{ maxWidth: '800px', mx: 'auto', lineHeight: 1.8 }}>
              Aspire Coordination Australia Pty Ltd was established to deliver a full spectrum of Engineering 
              and Project Management services tailored to various industry sectors. Operating out of Queensland, 
              Australia, we specialize in creating and implementing complete solutions designed to meet the 
              specific requirements of each client.
            </Typography>
          </Paper>

          {/* Team Section */}
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                mb: 3,
                background: '#3D52A0',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Our Team at Aspire Coordination Australia
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ maxWidth: '700px', mx: 'auto', mb: 6 }}>
              Our team is composed of highly skilled and qualified professionals who bring a wealth of 
              experience and expertise to every project.
            </Typography>

            {/* Feature Cards */}
            <Grid container spacing={4}>
              {features.map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 8
                      }
                    }}
                  >
                    <CardContent sx={{ p: 4, textAlign: 'center' }}>
                      <Box 
                        sx={{ 
                          display: 'inline-flex',
                          p: 2,
                          borderRadius: '50%',
                          bgcolor: '#3D52A0',
                          color: 'white',
                          mb: 3
                        }}
                      >
                        {feature.icon}
                      </Box>
                      <Typography 
                        variant="h5" 
                        component="div" 
                        sx={{ 
                          fontWeight: 'bold',
                          borderRadius: '20px',
                          mb: 2
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="text.secondary"
                        sx={{ lineHeight: 1.7 }}
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Values Section */}
          <Paper 
            elevation={2}
            sx={{ 
              p: 6,
              borderRadius: '16px',
              bgcolor: 'white',
              textAlign: 'center'
            }}
          >
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                mb: 3,
                background: '#3D52A0',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Our Values
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ maxWidth: '800px', mx: 'auto' }}>
              We are committed to delivering exceptional service through innovation, integrity, and 
              excellence in everything we do. Our focus is on building lasting relationships with our 
              clients and providing solutions that drive their success.
            </Typography>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default About;