import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box as MuiBox 
} from '@mui/material';
import image1 from '../assets/circuit-board-close-up-with-different-connections-min.jpg'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
}));

const data = [
  {
    title: "Proactive Design Technique for Enhanched Reliabilty",
    items: [
      "At our Hardware Services division, we prioritize proactive design techniques to ensure the highest levels of reliability and longevity for your critical infrastructure.      "
    ],
  },
  {
    title: "Sophsticated Technology Integration",
    items: [
      "Our Hardware Services team leverages state-of-the-art solutions to meet and exceed industry standards. From advanced materials to precision engineering, we equip your infrastructure with the tools needed to withstand the challenges of today and tomorrow."
    ],
  },
  {
    title: "Adherence to Stringet Standards",
    items: [
      "Whether it’s compliance with regulatory requirements or exceeding performance benchmarks, our commitment ensures your infrastructure operates at peak efficiency with minimal downtime."
    ],
  },
];

// Custom styles for left-side accordion
const LeftSideAccordion = ({ faqs }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MuiBox sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            '&:before': {
              display: 'none',
            },
            marginBottom: 2,
            borderRadius: 2,
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowLeftIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: 0,
                marginLeft: 1,
              },
              '& .MuiAccordionSummary-content': {
                marginLeft: 1,
              },
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer.map((point, pointIndex) => (
                <MuiBox 
                  key={pointIndex} 
                  component="p" 
                  sx={{ 
                    margin: 0, 
                    padding: '8px 0',
                    '&:not(:last-child)': {
                      borderBottom: '1px solid rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  • {point}
                </MuiBox>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </MuiBox>
  );
};

const Hardwareservices = () => {
  const faqData = [
    {
      question: "Productive Design Techniques for Enhanced Reliability",
      answer: [
        "In order to guarantee the maximum levels of dependability and lifespan for your vital infrastructure, our Hardware Services division places a strong priority on proactive design processes.",
      ]
    },
    {
      question: "Sophisticated Technology Integration",
      answer: [
        "Our Hardware Services team meets and beyond industry standards by utilising cutting-edge technologies. We give your infrastructure the resources it needs to meet the challenges of the present and the future, from cutting-edge materials to precise engineering."
      ]
    },
    {
        question: "Adherence to Stringent Standards",
        answer: [
          "Our dedication guarantees that your infrastructure runs at maximum efficiency with the least amount of downtime, whether that means meeting regulatory standards or surpassing performance criteria."
        ]
      }
  ];

  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', bgcolor: '#f0f0f0', fontWeight: 'bold'}}>
                Hardware Development
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        Hardware Development                       
                    </Typography>
                    <Typography>We at Aspire are experts at creating complex hardware solutions, with a focus on wireless, embedded, and rugged technologies for a range of uses, including automation, smart metering, energy and utilities, and industrial electronics. With a track record of successfully assisting our clients in all stages of hardware development, we provide full-service solutions from product idea to production, including design and development, architectural selection, integration of new technologies, and manufacturing support. Additionally, we assist clients with re-engineering, migrating their current goods to new technologies, and adjusting to the demands of the market.</Typography><br/>
                        
                       
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{ backgroundColor: "#f9f9f9", py: 6 }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            color: "#2c2c2c",
            fontWeight: 700,
            mb: 4,
            textAlign: "center",
          }}
        >
          Our Expertise Areas
        </Typography>
        <Grid container spacing={4}>
          {data.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 3,
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#3D52A0",
                    fontWeight: 600,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  {section.title}
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex} style={{ marginBottom: "8px", color: "#555" }}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#fff'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant='h3' sx={{fontWeight: 'bold'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px'}}>Get in Touch with us</Typography>
                    <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
                </Grid>

                <Grid item xs={12} md={6} sx={{borderRadius: '2px solid'}}>
                    <Box 
                      sx={{
                        border: '2px solid #ccc', 
                        borderRadius: '8px',      
                        padding: '20px',          
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                        bgcolor: '#f0f0f0',
                                 
                      }}
                    >
                      <Typography
                      variant='h5'
                      sx={{
                          color: '#000',
                          marginBottom: '20px',
                          borderRadius: '12px solid',
                          borderColor: 'black'
                      }}>
                          Expertise
                      </Typography>
                      
                      {/* Added FAQ Section */}
                      <LeftSideAccordion faqs={faqData} />
                    </Box>
                </Grid>
            </Grid>
            </Container>
        </Box>
    </Box>
  )
}

export default Hardwareservices