import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import image1 from '../assets/person-working-html-computer.jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

const Testingandvalidation = () => {
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', fontWeight: 'bold'}}>
                Testing and Validation
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        Testing and Validation           
                    </Typography>
                    <Typography
                        sx={{
                            marginTop: '25px',
                        }}>We develop and implement both short-term and long-term testing and validation strategies for our clients to guarantee quality, decrease costs, and notify teams of any serious threats. With extensive experience developing robust hardware and software solutions both for our big international clients and internally, we have honed an automation mindset and testing and validation procedure that guarantees our clients receive the best of both worlds.</Typography>
                    
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', textAlign: 'center', bgcolor: '#f0f0f0'}}>
            <Typography variant='h4' sx={{fontWeight: 'bold'}}>Make an Enquiry</Typography>
            <Typography variant='h6' sx={{marginTop: '15px'}}>Get in Touch with Us</Typography>
            <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
        </Box>

       
    </Box>
  )
}

export default Testingandvalidation
