import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import {useState} from 'react'
import { Link } from 'react-router-dom';
import svg from '../assets/svg2.svg'
import image1 from '../assets/close-up-hand-holding-smartphone.jpg'
import image2 from '../assets/saas-concept-collage (1).jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', 
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
}));

// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: '#fff',
//   color: '#fff',
//   padding: theme.spacing(10, 0),
//   textAlign: 'center',
//   backgroundImage: `url(${image1})`,
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover', // Adjust as needed: 'contain', 'cover', 'auto'
//   backgroundPosition: 'center',
// }));


const data = [
    {
      title: "IoT Edge & Cloud Integration",
      items: [
        "We assist clients in connecting cloud platforms, historians, and control systems to IoT sensors, RTUs, PLCs, relays, controllers, and other field devices. Azure, AWS, Google Cloud, and IBM Cloud are among the platforms we are well-versed in. For your upcoming integration project, we would be the perfect collaborator."
      ],
      id: 'cloud-integration'
    },
    {
      title: "Legacy Protocol Migration",
      items: [
        "Are you concerned that your fleet has many outdated or legacy devices that you do not yet wish to discard? We always integrate your products with new technology and legacy protocols. You can improve legacy interfaces with our assistance."
      ],
      id: 'legacy-migration'
    },
    {
      title: "IT/OT Integration",
      items: [
        "The seamless integration of IT and OT systems is crucial as digital transformation becomes more prevalent in the industrial and utility sectors. We are positioned as a family to assist our customers with their digital transformation and integration roadmap because we have experience integrating various systems, devices, and applications."
      ],
      id: 'it-ot-integration'
    },
  ];

const Digitaltransformation = () => {
    const [showExpertise, setShowExpertise] = useState(false);
  return (
    <Box flexGrow={1}>
        <Box >
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', bgcolor: '#f0f0f0', fontWeight: 'bold'}}>
              Digital Transformation
            </Typography>
        </HeroSection>
       
        </Box>

        <Box sx={{ padding: '30px' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  color: '#3D52A0',
                }}
              >
                Digital Transformation
              </Typography>
              <Typography sx={{ marginTop: '25px' }}>
              We assist clients in integrating cloud platforms with their field data. We provide clients end-to-end solutions for digital transformation in the energy, utilities, commercial, and industrial sectors thanks to our strong edge and cloud expertise in AWS, Azure, Google Cloud, IBM Cloud, as well as docker and container technology, Streams technology, and UX tools.
              </Typography>
              
            </Grid>
            <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image2}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
          </Grid>
        </Container>
      </Box>

      

        

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
           <Container maxWidth="lg">
                <Typography 
                    variant='h5'
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}
                >What Aspire Digital Transformation Service Offer?
                </Typography>
                <Typography 
                    sx={{
                        marginTop: '15px',
                        textAlign: 'left'
                    }}
                >
                    Utilities and businesses can more readily explore better revenue opportunities, plan resource allocation effectively, and improve overall cyber-security with more dependable insights obtained by correlating IT and OT data. As one of the top suppliers of utility transformation solutions worldwide, we supply the utility ecosystem with cutting-edge solutions that enable them to create new business models and sustainable energy production and delivery plans.
                    <ul>
                        <li>Streamline operations, optimize data analysis, and manage power distribution in an increasingly complex energy market.</li>
                        <li>Adapt cloud and IoT technologies without disrupting existing systems and processes.</li>
                        <li>Automate utility operations, empowering new service development, and driving better customer satisfaction.</li>
                        <li>IoT Integration services incorporate migration to cloud platforms, edge software and hardware adaptation, and data analysis.</li>
                        <li>Optimize cost by reducing building blocks such as data storage, computing power, and internet bandwidth.</li>
                    </ul>
                </Typography>
           </Container>
        </Box>


        <Box sx={{ backgroundColor: "#f9f9f9", py: 6 }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            color: "#3D52A0",
            fontWeight: 700,
            mb: 4,
            textAlign: "center",
          }}
        >
          Our Expertise Areas
        </Typography>
        <Grid container spacing={4}>
          {data.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 3,
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#3D52A0",
                    fontWeight: 600,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  {section.title}
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex} style={{ marginBottom: "8px", color: "#555" }}>
                      {item}
                    </li>
                  ))}
                </ul>
                <Button 
                  variant="contained" 
                  color="#fff"
                  backgroundColor='#3D52A0'
                  sx={{ mt: 2 }}
                  component={Link} 
                  to={`/services/${section.id}`} // Update with your route path
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
        </Box>
    </Box>
  )
}

export default Digitaltransformation
