import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box as MuiBox 
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

  const LeftSideAccordion = ({ faqs }) => {
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <MuiBox sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
        {faqs.map((faq, index) => (
          <Accordion 
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              '&:before': {
                display: 'none',
              },
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowLeftIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              sx={{
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper': {
                  marginRight: 0,
                  marginLeft: 1,
                },
                '& .MuiAccordionSummary-content': {
                  marginLeft: 1,
                },
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {faq.answer.map((point, pointIndex) => (
                  <MuiBox 
                    key={pointIndex} 
                    component="p" 
                    sx={{ 
                      margin: 0, 
                      padding: '8px 0',
                      '&:not(:last-child)': {
                        borderBottom: '1px solid rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    • {point}
                  </MuiBox>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </MuiBox>
    );
  }; 

const Legacy = () => {
    
    const faqData = [
        {
          question: "Utility Connectivity",
          answer: [
            "IEC 61850",
            "DNP3 / IEEE1815",
            "IEC 60870-5-103/101/1-4",
            "IEEE C37.118",
            "IEC62056 / DLMS-COSEM"
          ]
        },
        {
          question: "Industrial Connectivity",
          answer: [
            "Modbus",
            "ContrilNet",
            "BACnet",
            "ANSI",
            "Ethernrt IP",
            "Lon Talk"
          ]
        },
        {
            question: "Legacy Connectivity",
            answer: [
             "EATON RTK",
             "ABB Triquard P2P",
             "ZIV PROCOME",
             "Rockwell",
             "ABB SPA",
             "IEC61107"
            ]
        },
        {
            question: "Ancillary Connectivity",
            answer: [
              "MQTT",
              "OPC UA",
              "NMEA",
              "SNMP",
              "SFTP/SCP"
            ]
        },
        {
            question: "IoT Platform Connectivity",
            answer: [
             "Amazon AWS",
             "Microsoft Azure",
             "Google Cloud",
             "MQTT"
            ]
        }
      ];
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', fontWeight: 'bold'}}>
            Legacy Protocol Migration
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
           <Container maxWidth="lg">
           <Grid container spacing={4} alignItems="center">
                {/* <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid> */}

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                        Legacy Protocol Migration 
                    </Typography>
                    <Typography sx={{marginTop: '25px'}}>
                    Concerned about how to incorporate your items with outdated protocols? Legacy interfaces can be improved, upgraded, or implemented with our assistance. We can assist you with integrating various devices into a single data flow architecture for digital transformation, as well as implementing standard and legacy protocols.
                        </Typography>
                      
                </Grid>
            </Grid>
           </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                   <Box padding='40px'>
                   <Typography variant='h3' sx={{fontWeight: 'bold', textAlign: 'center'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px', textAlign: 'center'}}>Get in Touch with us</Typography>
                     <Box padding='15px'>
                     <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
                     </Box>
                    </Box>            
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                         sx={{
                            border: '2px solid #ccc', 
                            borderRadius: '8px',      
                            padding: '20px',          
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                            bgcolor: '#fff',          
                        }}
                    >
                         <Typography
                          variant="h5"
                          sx={{
                              color: '#000',
                              marginBottom: '20px',
                              fontWeight: 'bold',   // Optional for emphasis
                          }}
                      >
                          Expertise
                      </Typography>
                      
                      {/* Added FAQ Section */}
                      <LeftSideAccordion faqs={faqData} />
                    </Box>
                </Grid>
            </Grid>
            </Container>
        </Box>
    </Box>
  )
}

export default Legacy
