import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Component/Header';
import EdgeandCloud from './Services/Digital Transformation/EdgeandCloud';
import Legacy from './Services/Digital Transformation/Legacy';
import ITintegration from './Services/Digital Transformation/ITintegration';
import Advancedgrid from './Services/Digital Transformation/Advancedgrid';
import Newproduct from './Services/Product Engineering/Newproduct';
import Lifecycle from './Services/Product Engineering/Lifecycle';
import Testingandvalidation from './Services/Product Engineering/Testingandvalidation';
import Hardwareservices from './Services/Product Engineering/Hardwareservices';
import Solutionbased from './Services/Product Engineering/Solutionbased';
import Certification from './Services/Product Engineering/Certification';
import Protocoltraining from './Services/Protocolconsultancy/Protocoltraining';
import FeatureList from './Services/Protocolconsultancy/FeatureList';
import Digitaltransformation from './Services/Digitaltransformation';
import Productengeering from './Services/Productengeering';
import Footer from './Component/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Career from './Pages/Career';
import Contact from './Pages/Contact';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/contact-us' element={<Contact/>}/>
        <Route path='/services/cloud-integration' element={<EdgeandCloud/>}/>
        <Route path='/services/legacy-migration' element={<Legacy/>}/>
        <Route path='/services/it-ot-integration' element={<ITintegration/>}/>
        <Route path='/services/advanced-grid' element={<Advancedgrid/>}/>
        <Route path='/services/new-product-development' element={<Newproduct/>}/>
        <Route path='/services/life-cycle-extension' element={<Lifecycle/>}/>
        <Route path='/services/testing-validation' element={<Testingandvalidation/>}/>
        <Route path='/services/hardware-development' element={<Hardwareservices/>}/>
        <Route path='/services/solutioning' element={<Solutionbased/>}/>
        <Route path='/services/certification-compliance' element={<Certification/>}/>
        <Route path='/services/protocol-training' element={<Protocoltraining/>}/>
        <Route path='/services/feature-list' element={<FeatureList/>}/>
        <Route path='/services/digital-transformation' element={<Digitaltransformation/>}/>
        <Route path='/services/product-engineering' element={<Productengeering/>}/> 
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
