import React, { useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Toolbar, 
  IconButton, 
  Paper,
  styled,
  Grid
} from '@mui/material';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MapPin, Mail, Phone } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  marginRight: theme.spacing(2),
  "&:hover": {
    color: "#90caf9",
    transform: "translateY(-3px)",
    transition: "all 0.3s ease-in-out"
  }
}));

const ContactCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '16px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#3D52A0',
  borderRadius: '50%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box sx={{ 
      bgcolor: '#f8fafc', 
      minHeight: '100vh',
      pt: 8,
      pb: 12 
    }}>
      <Toolbar />
      
      {/* Header Section */}
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: 'bold',
              mb: 2,
              background: '#3D52A0',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Get in Touch
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ maxWidth: '600px', mx: 'auto' }}>
            We're here to help and answer any questions you might have
          </Typography>
        </Box>

        {/* Contact Cards */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12} md={4}>
            <ContactCard elevation={3}>
              <IconWrapper>
                <MapPin size={24} />
              </IconWrapper>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Visit Us
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Ground Floor, 288 Musgrave Road, Coopers Plains,<br/>
                QLD, Australia 4108
              </Typography>
            </ContactCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <ContactCard elevation={3}>
              <IconWrapper>
                <Mail size={24} />
              </IconWrapper>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Email Us
              </Typography>
              <Typography variant="body1" color="text.secondary">
                admin@aspirecoordination.com
              </Typography>
            </ContactCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <ContactCard elevation={3}>
              <IconWrapper>
                <Phone size={24} />
              </IconWrapper>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Call Us
              </Typography>
              <Typography variant="body1" color="text.secondary">
              +61 403 786 083
              </Typography>
            </ContactCard>
          </Grid>
        </Grid>

        {/* Social Media Section */}
        <Paper 
          elevation={3}
          sx={{ 
            p: 6, 
            borderRadius: '16px',
            background: '#f0f0f0',
            color: '#000',
            textAlign: 'center'
          }}
        >
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
            Connect With Us
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <IconButton 
              component="a" 
              href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61568696157426"
              sx={{ 
                color: '#000',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-3px)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <FaFacebook size={24} />
            </IconButton>
            <IconButton 
              component="a" 
              href="https://x.com/AspireCDN7"
              sx={{ 
                color: '#000',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-3px)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <FaTwitter size={24} />
            </IconButton>
            <IconButton 
              component="a" 
              href="https://www.instagram.com/aspirecoordination7/"
              sx={{ 
                color: '#000',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-3px)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <FaInstagram size={24} />
            </IconButton>
            <IconButton 
              component="a" 
              href="https://www.linkedin.com/company/aspire-coordination/"
              sx={{ 
                color: '#000',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-3px)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <FaLinkedin size={24} />
            </IconButton>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Contact;