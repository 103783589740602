import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import {useState} from 'react'
import { Link } from 'react-router-dom';
import image1 from '../assets/futuristic-smart-city-with-5g-global-network-technology (1).jpg'
// const HeroSection = styled(Box)(({ theme }) => ({
//     backgroundColor: '#fff', 
//     color: '#fff',
//     padding: theme.spacing(10, 0),
//     textAlign: 'center',
// }));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#fff',
  padding: theme.spacing(10, 0),
  textAlign: 'center',
  backgroundImage: `url(${image1})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover', 
  backgroundPosition: 'center',
}));

const data = [
    {
      title: "Edge IoT Support ",
      items: [
        "Legacy and standard field protocol support for Commercial, Industrial, Energy and Utility applications",
        "Support AWS Green Grass, Lambda, UBIQSENS, Free RTOS, Azure Edge, Raspberry Pi, Arduino and more",
        "Custom development of IoT and Sensor protocols and applications",
      ],
    },
    {
      title: "Cloud Integration Support",
      items: [
        "AWS IoT Integration and solution implementation using the entire AWS IoT Stack including IoT Core, Sage maker, Kinesis Streams, Kinesis Analytics, IoT Analytics, Quicksight, and data storeusingS3",
        "Google Cloud–Expand similar to Google Cloud"
      ],
    },
    {
      title: "Edge andCloud Security",
      items: [
        "With our strong expertise in NERC / CIP ready product development and IEC 62351 and IEEE 1686security requirements, we are able to design, build, and deploy strong security architecture for IoT implementations.",
        "We provide solutions that harden your head end platform on the cloud and cloud integration and keep your trust chains secure and compliant to various regulatory and industry-standard guidelines and practices."
      ],
    },
  ];

const EdgeandCloud = () => {
    // const [showExpertise, setShowExpertise] = useState(false);
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection  sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h4' sx={{color: '#000', fontWeight: 'bold'}}>
                IOT Edge and Cloud Integration
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{ padding: '30px' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  color: '#000',
                }}
              >
                IOT Edge and Cloud Integration
              </Typography>
              <Typography sx={{ marginTop: '25px' }}>
                IoT enabled digital transformation is critical to most industries, especially for the Energy & Utilities Industry. It provides significant bottom-line benefits with predictive and preventive maintenance, analytics, real-time visualization, continuous customer engagement, and reporting and adopts AI/ML technology. Aspire consulting help you with the IoT / Cloud Integration and digital transformation initiatives to achieve your digital, cloud, and IoT goals.
              </Typography>
              <Typography sx={{ marginTop: '25px' }}>
                We engage with our customers in analyzing field/sensor readiness for integration, application integration readiness, and solution landscape to arrive at the right solution for the digital journey. We then assist in enabling legacy and new IoT and device/equipment infrastructure to the Cloud platform of choice, leveraging kalki.io integration middleware with 100+ protocol support. With a full-service portfolio of hardware, software, edge, analytics, AI/ML, and cloud capabilities, together with SCADA/DMS/EMS/RTU/Controller/PLC expertise, we provide a comprehensive set of migration integration offerings to our customers.
              </Typography>
              
            </Grid>
            <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
          </Grid>
        </Container>
      </Box>

      
        <Box sx={{ backgroundColor: "#f9f9f9", py: 6 }}>
          <Container>
            <Typography
              variant="h4"
              sx={{
                color: "#2c2c2c",
                fontWeight: 700,
                mb: 4,
                textAlign: "center",
              }}
            >
              Our Expertise Areas
            </Typography>
            <Grid container spacing={4}>
              {data.map((section, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      p: 3,
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#3D52A0",
                        fontWeight: 600,
                        mb: 2,
                        textAlign: "center",
                      }}
                    >
                      {section.title}
                    </Typography>
                    <ul style={{ paddingLeft: "20px" }}>
                      {section.items.map((item, itemIndex) => (
                        <li key={itemIndex} style={{ marginBottom: "8px", color: "#555" }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      

        

        <Box sx={{padding: '30px', bgcolor: '#fff'}}>
           <Container maxWidth="lg">
           <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant='h3' sx={{fontWeight: 'bold', textAlign: 'center'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px', textAlign: 'center'}}>Get in Touch with us</Typography>
                    <Button 
                    component={Link} 
                    to={`/contact-us`}
                    sx={{marginTop: '25px', alignItems: 'center'}}>Contact Us</Button>             
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box 
                         sx={{
                            border: '2px solid #ccc', 
                            borderRadius: '8px',      
                            padding: '20px',          
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                            bgcolor: '#f0f0f0',          
                        }}
                    >
                        <Typography
                        variant='h5'
                        sx={{
                            
                            color: '#000'
                        }}>
                            Key Feature
                            <Typography>
                            <ul>
                                <li>IoT readiness & Application Integration readiness assessment</li>
                                <li>IoT adaptation roadmap, technology identification & implementation</li>
                                <li>Cloud Integration roadmap, technology identification & implementation</li>
                                <li>IoT & Cloud Security</li>
                                <li>On-boarding & roll-out support and maintenance</li>
                            </ul>
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
           </Container>
        </Box>
    </Box>
  )
}

export default EdgeandCloud
