import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import svg1 from './assets/svg3.svg'
import image1 from '../assets/laptop-which-there-is-world-people-drawn.jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

const Certification = () => {
  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h3' sx={{color: '#000', bgcolor: '#f0f0f0', fontWeight: 'bold'}}>
            Certification & Compliance
            </Typography>
        </HeroSection>
       

        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                       Certification & Compliance         
                    </Typography>
                    
                    <Typography
                        sx={{
                            marginTop: '25px'
                        }}>The Aspire Services team helps you get your product certified by utilising their expertise in creating interoperable communication interfaces. In order to guarantee that your product satisfies the necessary standards, Aspire may collaborate with your R&D/technical team throughout the many stages of product development.
                        <ul>
                            <li>Environmental tests (Temperature and Humidity tests)</li>
                            <li>Conformance to Hardware standards</li>
                            <li>Compliance with security standards</li>
                            <li>ECommunication interface conformance</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
            <Container maxWidth="lg">
               
                <Typography>Aspire uses both standard and proprietary tools and simulators to provide precertification services for protocols such as IEC 62056 (DLMS and its related standards), IEC 61850, etc. Meter Explorer, DLMS meter simulators, functional assessment tools for country-specific partners, the IEC 61850 test suite, and several bespoke tools for certain test scenarios are among the in-house tools. The functional and performance criteria of the products are included in the testing's scope.  A trouble-free conformity test with assured results is ensured by this precertification procedure. </Typography>
            </Container>
        </Box>

        <Box sx={{padding: '30px', textAlign: 'center'}}>
            <Typography 
                variant='h4'
                sx={{
                    fontWeight: 'bold'
                }}
            >Make An Enqiry</Typography>
            <Typography 
                variant='h5'
                sx={{
                    marginTop: '15px'
                }}
            >Get in Touch with Us</Typography>
            <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>
        </Box>
    </Box>
  )
}

export default Certification
