import { Typography, Box, styled, Container, Grid, Button } from '@mui/material'
import React from 'react'
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box as MuiBox 
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
import image1 from '../assets/smart-microchip-background-motherboard-closeup-technology-remix-min.jpg'
const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff', // Hero Section Background
    color: '#fff',
    padding: theme.spacing(10, 0),
    textAlign: 'center',
  }));

// Custom styles for left-side accordion
const LeftSideAccordion = ({ faqs }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MuiBox sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            '&:before': {
              display: 'none',
            },
            marginBottom: 2,
            borderRadius: 2,
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowLeftIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: 0,
                marginLeft: 1,
              },
              '& .MuiAccordionSummary-content': {
                marginLeft: 1,
              },
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer.map((point, pointIndex) => (
                <MuiBox 
                  key={pointIndex} 
                  component="p" 
                  sx={{ 
                    margin: 0, 
                    padding: '8px 0',
                    '&:not(:last-child)': {
                      borderBottom: '1px solid rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  • {point}
                </MuiBox>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </MuiBox>
  );
};

const Advancedgrid = () => {
  const faqData = [
    {
      question: "Lab Infrastructure as a Service",
      answer: [
        "Provide environment for testing and validating virtualized solutions.",
        "Assistance in deploying and setting up hardware brought by external participants.",
        "Demonstration of Virtual Protection Relays",
        "Hands-on involving simulators and test tools.",
      ]
    },
    {
      question: "Testing and Evaluation",
      answer: [
        "Conduct performance benchmarking.",
        "Identify improvement areas",
        "Create testing environment with secure remote access.",
        "Deploy, integrate, and evaluate VPRs for the vendors."
      ]
    },
    {
        question: "Engineering Service",
        answer: [
          "Implement recommendations",
          "Porting a Substation IED for virtualization and centralized system.",
        ]
      }
  ];

  return (
    <Box flexGrow={1}>
        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
        <HeroSection sx={{bgcolor: '#f0f0f0'}}>
            <Typography variant='h4' sx={{color: '#000', fontWeight: 'bold'}}>
            Advanced Grid Automation Technology Lab
            </Typography>
        </HeroSection>
        </Box>

        <Box sx={{padding: '30px'}}>
            <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography
                     variant='h5'
                     sx={{
                        fontWeight: 'bold',
                        color: '#000'
                     }}>
                       Advanced Grid Automation Technology Lab 
                    </Typography>
                    <Typography sx={{marginTop: '25px'}}>Aspire established an Advanced Grid Automation Technology Lab with assistance from Intel Corporation. Companies in the grid automation community can integrate, assess, and encourage the use of sophisticated computer technologies in the substation to modernise protection, automation, and control (PAC) in this real and virtual laboratory.</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box 
                        component="img"
                        src={image1}
                        sx={{
                            width: '100%'
                        }}
                    />                  
                </Grid>
            </Grid>
            </Container>
        </Box>

        <Box sx={{padding: '30px', bgcolor: '#f0f0f0'}}>
           <Container maxWidth="lg">
           <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant='h3' sx={{fontWeight: 'bold', textAlign: 'center'}}>Make An Enquiry</Typography>
                    <Typography variant='h6' sx={{color: '#000', marginTop: '25px', textAlign: 'center'}}>Get in Touch with us</Typography>
                    <Button 
                     component={Link} 
                     to={`/contact-us`}
                     sx={{marginTop: '25px', textAlign: 'center' }}>Contact Us</Button>            
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      border: '2px solid #ccc', 
                      borderRadius: '8px',      
                      padding: '20px',          
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                      bgcolor: '#fff',          
                    }}
                  >
                    <Typography
                     variant='h5'
                     sx={{
                        color: '#000',
                        marginBottom: '20px'
                     }}>
                        Offerings
                    </Typography>
                  
                    
                    {/* Added FAQ Section */}
                    <LeftSideAccordion faqs={faqData} />
                  </Box>
                </Grid>
            </Grid>
           </Container>
        </Box>
    </Box>
  )
}

export default Advancedgrid